import React from 'react';
import { Link } from 'react-router-dom';
import style from './didactic.module.css';

import Footer from '../Footer';
import Header from './HeaderDidactic';

import { useCourses } from '../../CoursesContext';

function Didactic() {
  const { courses, loading, error } = useCourses();
  
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!courses) return <div>No course found</div>;

  const links = [
    { name: "Strona główna", url: "/" },
    { name: "Wszystkie kursy", url: "/course" }
  ];

  console.log("Courses: ", courses);

 
  return (
    <div>
      <Header links={links} />
      <div className='container'>
        <h1 className="col-12 text-center p-5">Wszystkie kursy</h1>
        <div className="row justify-content-center">
          {courses.map((course) => (
            <React.Fragment key={course.CourseId}>
              <div className={`${style.didacticBlock} col-12 col-md-6 text-center rounded mb-4`}>
                <div className="row justify-content-center">
                  <div className={`${style.widthCourse1} text-end fw-bold`}>Nazwa:</div>
                  <div className="col text-start fw-bold">
                    <Link to={`/course/${encodeURI(course.CourseName)}`} className='text-decoration-none'>{course.CourseName}</Link>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className={`${style.widthCourse1} text-end fw-bold`}>Opis:</div>
                  <div className="col text-start">{course.Description}</div>
                </div>
              </div>
              <div className="w-90"></div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Didactic;
