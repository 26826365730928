import React, { useState, useEffect } from "react";
import {useCourses } from '../../../../CoursesContext';

interface CourseFormProps {
  selectedCourse: number | null;
  setSelectedCourse: React.Dispatch<React.SetStateAction<number | null>>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
}

const CourseForm: React.FC<CourseFormProps> = ({
  selectedCourse,
  setSelectedCourse,
  handleChange,
}) => {
  const {
    courses,
    topicSections,
    UpdateCourse,
    DeleteCourse,
    DeleteTopic,
    DeleteTopicSection,
  } = useCourses();


  const [courseName, setCourseName] = useState<string>("");
  const [courseDescription, setCourseDescription] = useState<string>("");


  useEffect(() => {
    if (selectedCourse) {
      const course = courses?.find(
        (course) => course.CourseId === selectedCourse
      );
      if (course) {
        setCourseName(course.CourseName);
        setCourseDescription(course.Description);
      }
    }
  }, [selectedCourse, courses]);


  const handleEditCourse = async () => {
    console.log("Edit course with ID:", selectedCourse);
    console.log("Course Name:", courseName);
    console.log("Course Description:", courseDescription);
    if (selectedCourse) {
      await UpdateCourse(selectedCourse, courseName, courseDescription);
      setSelectedCourse(null);
      setCourseName("");
      setCourseDescription("");
    }
  };
  
  const handleDeleteCourse = async (courseId: number) => {
    try {
      // Check if there are topics related to the course
      const relatedTopics = courses?.find(
        (course) => course.CourseId === courseId
      )?.topics;
  
      // Delete any related topic
      if (relatedTopics && relatedTopics.length > 0) {
        for (const topic of relatedTopics) {
          // Find all topic sections associated with the topic
          const relatedTopicSections = topicSections?.filter(
            (ts) => ts.topic_id === topic.topicId
          );
  
          // Delete each topic section associated with the topic
          if (relatedTopicSections) {
            for (const ts of relatedTopicSections) {
              await DeleteTopicSection(ts.topic_id, ts.section_id, ts.sequence);
            }
          }
          await console.log(
            `Successfully deleted each topic section associated with the topic ID: ${topic.topicId}`
          );
          await DeleteTopic(topic.topicId);
          console.log("Deleted related topic ID: ", topic.topicId);
        }
      }
      // Delete the course after removing all related topics
      await DeleteCourse(courseId);
      setSelectedCourse(null);
      setCourseName("");
      setCourseDescription("");
      await console.log(`Successfully deleted course ID: ${courseId}`);
    } catch (error) {
      console.error("Failed to delete course", error);
    }
  };

return (
  <>
    <div className="mb-3 text-center">
      <label htmlFor="courseSelect" className="form-label">
        <p className='fs-3 fw-bold'>Wybierz kurs</p>
      </label>
      <select
        id="courseSelect"
        name="courseSelect"
        className="form-select text-center"
        value={selectedCourse || ''}
        onChange={handleChange}
      >
        <option value="">-- Wybierz kurs --</option>
        {courses?.map(course => (
          <option key={course.CourseId} value={course.CourseId}>
            {course.CourseName}
          </option>
        ))}
      </select>
    </div>
    {selectedCourse && (
      <>
        <div className="mb-3 d-flex align-items-center">
          <label htmlFor="courseName" className="form-label me-2">Nazwa kursu: </label>
          <input
            id="courseName"
            name="courseName"
            type="text"
            className="form-control w-auto"
            value={courseName}
            onChange={(e) => setCourseName(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="courseDescription" className="form-label me-2">Opis kursu</label>
          <textarea
            id="courseDescription"
            name="courseDescription"
            className="form-control"
            value={courseDescription}
            onChange={(e) => setCourseDescription(e.target.value)}
          />
        </div>
        <div className='mb-3 text-center'>
          <button type="button" className="btn btn-primary me-1" onClick={handleEditCourse}>Edytuj kurs</button>
          <button type="button" className="btn btn-danger" onClick={() => selectedCourse && handleDeleteCourse(selectedCourse)}>Usuń kurs</button>
        </div>
      </>
    )}
  </>
);
};

export default CourseForm;
