// SectionTemplates.tsx
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/master/AVAILABLE_STYLES_PRISM.MD
import { solarizedlight, duotoneLight, oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
// Available languages
// https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/master/AVAILABLE_LANGUAGES_PRISM.MD
// textile - as a result has structured HTML markup.

interface TextProps {
  context: string[];
}

interface LinkProps {
  context: string[];
}

interface ImageProps {
  context: string[];
}

interface CodeProps {
  context: [string, string][];
}

export function TextSection({ context }:TextProps) {
    const [text] = context;
    return (
        <>
            <p className='ms-4 me-4 mt-1 mb-1' style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: text }} />
        </>
    );
};

export function LinkSection({ context }: LinkProps) {
    const [link, name] = context;
    return (
        <p className='ms-4 me-4'>Link: <a href={link}>{name}</a></p>
      );
};

export function ImageSection ({ context }: ImageProps) {
  const [imageLink, imageSourceName, imageSourceLink] = context;
  return (
      <div className='text-center'>
          <img src={imageLink} alt='reload-page' className="img-thumbnail" style={{ maxWidth: '80%', margin: '10px 0' }} />
          <p>Źródło: <a href={imageSourceLink} target="_blank" rel="noopener noreferrer">{imageSourceName}</a></p>
      </div>
  );
};

export function CodeBlock({ context }: CodeProps) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <>
      {context.map((item, index) => {
        const [language, code] = item;
        return (
          <div key={index} style={{ margin: "10px", position: 'relative', marginBottom: '1em' }}>
            <CopyToClipboard text={code} onCopy={handleCopy}>
              <button style={{ position: 'absolute', top: 10, right: 10 }}>
                {isCopied ? 'Skopiowane!' : 'Kopiuj'}
              </button>
            </CopyToClipboard>
            <SyntaxHighlighter language={language} style={oneLight}>
              {code}
            </SyntaxHighlighter>
            <span style={{position: 'absolute', bottom: 10, right: 10, color: '#888'}}>
              Język: {language}
            </span>
          </div>
        );
      })}
    </>
  );
}
