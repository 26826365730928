import Header from "../Header";
import Footer from "../Footer";
import style from "./signup.module.css";

import jakubBook2 from "../../assets/jakubBook2.jpg";
import userIcon from "../../assets/icon/person.png";
import emailIcon from "../../assets/icon/email.png";
import passwordIcon from "../../assets/icon/password.png";
import React, { useState } from "react";



function DidacticsEnter() {
  return (
    <div className={style.DidacticEnter}>
      <Header
        image1={jakubBook2}
        heightImage={200}
        title="Materiały dydaktyczne"
        description="Materiały dydaktyczne do nauki z różnych dziedzin. Do części teoretycznej dołączone są ćwiczenia umożliwiające utrwalenie zagadnień. Znaleźć można również testy sprawdzające zdobytą wiedzę."
        media={false}
        buttonMain={true}
      />
      <LoginSignUp />
      <Footer />
    </div>
  );
}

export default DidacticsEnter;

function LoginSignUp() {
  const [action, setAction] = useState<string>("Zaloguj");

  return (
    <div className={style.container}>
      <a href="/course">
        <div className={style.submitGuest}>Wejdź jako gość</div>
      </a>
       {/* <p>Obecnie jest możliwość wejścia tylko jako gość!</p>
      <div className={style.header}>
        <div className={style.text}>{action}</div>
        <div className={style.underline}></div>
      </div>
      <div className={style.inputs}>
        {action === "Zaloguj" ? (
          <div></div>
        ) : (
          <div className={style.input}>
            <img src={userIcon} alt="" />
            <input type="text" placeholder="Nazwa" />
          </div>
        )}

        <div className={style.input}>
          <img src={emailIcon} alt="" />
          <input type="email" placeholder="Email" />
        </div>
        <div className={style.input}>
          <img src={passwordIcon} alt="" />
          <input type="password" placeholder="Hasło" />
        </div>
      </div>
      {action === "Zarejestruj" ? (
        <div></div>
      ) : (
        <div className={style.forgotPassword}>
          Zapomniałeś hasła? <span>Przypomnij!</span>
        </div>
      )}
      <div className={style.submitContainer}>
        <div
          className={
            action === "Zaloguj"
              ? `${style.submit} ${style.gray}`
              : style.submit
          }
          onClick={() => setAction("Zarejestruj")}
        >
          Zarejestruj
        </div>
        <div
          className={
            action === "Zarejestruj"
              ? `${style.submit} ${style.gray}`
              : style.submit
          }
          onClick={() => setAction("Zaloguj")}
        >
          Zaloguj
        </div>
      </div> */}
    </div>
  );
}