import { useState } from "react";
import {Course, Section, Topic, TopicSection, SectionTemplate, Template, useSendRequest} from '../CoursesContext';

const domainAddress = process.env.REACT_APP_API_URL;

export const usePostMethods = () => {
  const [courses, setCourses] = useState<Course[] | null>(null);
  const [sections, setSections] = useState<Section[] | null>(null);
  const [topicSections, setTopicSections] = useState<TopicSection[] | null>(null);
  const [sectionTemplates, setSectionTemplates] = useState<SectionTemplate[] | null>(null);
  const [templates, setTemplates] = useState<Template[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const {SendRequest} = useSendRequest();

      const AddCourse = async (name: string, description: string) => {
        try {
          // 1. Send a POST to add a new course
          const result = await SendRequest(`${domainAddress}/backend/api/course`, "POST", {
            name,
            description,
          });
          
          // 2. Create a new course object
          const newCourse: Course = {
            CourseId: result.id,
            CourseName: name,
            Description: description,
          };
          
          // 3. Update course list
          setCourses((prevCourses) =>
            prevCourses ? [...prevCourses, newCourse] : [newCourse]
          );
        } catch (error) {
            setError(
                error instanceof Error ? error.message : "An unknown error occurred"
            );
        }
      };
    
      const AddTopic = async (courseId: number, name: string, details: string) => {
        try {
          // 1. Send a POST to add a new topic
          const result = await SendRequest(`${domainAddress}/backend/api/topic.php`, "POST", {
            course_id: courseId,
            name,
            details,
          });
          
          // 2. Create a new topic object
          const newTopic: Topic = {
            topicId: result.id,
            courseId,
            name,
            details,
          };
          
          // 3. Update course topics
          setCourses(
            (prevCourses) =>
              prevCourses?.map((course) =>
                course.CourseId === courseId
                  ? { ...course, topics: [...(course.topics || []), newTopic] }
                  : course
              ) || null
          );
        } catch (error) {
            setError(
                error instanceof Error ? error.message : "An unknown error occurred"
            );
        }
      };  
    
      const AddTopicSection = async (
        topicId: number,
        sectionId: number,
        sequence: number
      ) => {
        try {
          // 1. Send a POST to add a new topic-section relation
          await SendRequest(`${domainAddress}/backend/api/topicSection`, "POST", {
            topic_id: topicId,
            section_id: sectionId,
            sequence,
          });
          
          // 2. Create a new topic-section object
          const newTopicSection: TopicSection = {
            topic_id: topicId,
            section_id: sectionId,
            sequence,
          };
          
          // 3. Update topic sections
          setTopicSections((prevTopicSections) =>
            prevTopicSections ? [...prevTopicSections, newTopicSection] : [newTopicSection]
          );
        } catch (error) {
            setError(
                error instanceof Error ? error.message : "An unknown error occurred"
            );
        }
      };
    
      const AddSection = async (id: number, sectionName: string): Promise<number> => {
        const templateType = "defaultType";
        const templateContext = ["defaultContext"];
      
        try {
          // 1. Send a POST to add a new section
          await SendRequest(`${domainAddress}/backend/api/section`, "POST", {
            id,
            name: sectionName,
            templateType,
            templateContext,
          });
      
          // 2. Download all sections
          const sectionsData: Section[] = await SendRequest(
            `${domainAddress}/backend/api/section`,
            "GET",
            null
          );
      
          // 3. Find the newly added section
          const newSection = sectionsData.find(
            (section) => section.sectionName === sectionName
          );
      
          if (!newSection) {
            throw new Error("New section not found");
          }
      
          // 4. Update section status
          setSections((prevSections) =>
            prevSections ? [...prevSections, newSection] : [newSection]
          );
      
          return newSection.id;
        } catch (error) {
            setError(
                error instanceof Error ? error.message : "An unknown error occurred"
            );
          throw error;
        }
      };
    
      const AddSectionTemplate = async (
        sectionId: number,
        templateId: number,
        sequence: number
      ) => {
        try {
          // 1. Send a POST to add a new section-template relation
          await SendRequest(`${domainAddress}/backend/api/sectionTemplate`, "POST", {
            section_id: sectionId,
            template_id: templateId,
            sequence,
          });
      
          // 2. Create a new section-template object
          const newSectionTemplate: SectionTemplate = {
            section_id: sectionId,
            template_id: templateId,
            sequence,
          };
      
          // 3. Update section templates
          setSectionTemplates((prevSectionTemplates) =>
            prevSectionTemplates
              ? [...prevSectionTemplates, newSectionTemplate]
              : [newSectionTemplate]
          );
        } catch (error) {
            setError(
                error instanceof Error ? error.message : "An unknown error occurred"
            );
        }
      };
    
      const AddTemplate = async (type: string, context: string): Promise<number> => {
        try {
          // 1. Send a POST to add a new template
          await SendRequest(`${domainAddress}/backend/api/template`, "POST", {
            type,
            context,
          });
      
          // 2. Download all templates
          const templatesData: Template[] = await SendRequest(
            `${domainAddress}/backend/api/template`,
            "GET",
            null
          );
      
          // 3. Find the newly added template
          const newTemplate = templatesData.find(
            (template) => template.type === type && template.context === context
          );
      
          if (!newTemplate) {
            throw new Error("New template not found");
          }
      
          // 4. Update template status
          setTemplates((prevTemplates) =>
            prevTemplates ? [...prevTemplates, newTemplate] : [newTemplate]
          );
      
          // 5. Return the ID of the newly added template
          return newTemplate.id;
        } catch (error) {
          setError(
            error instanceof Error ? error.message : "An unknown error occurred"
          );
          throw error;
        }
      };

  return {
    AddCourse,
    AddTopic,
    AddTopicSection,
    AddSection,
    AddSectionTemplate,
    AddTemplate,
  };
};