import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { getCourses, getSections, getTopicSections, getTopics, getSectionTemplates, getTemplates } from './api/GetMethod';
import { usePutMethods } from "./api/PutMethod";
import { usePostMethods } from "./api/PostMethod";
import { useDeleteMethods } from "./api/DeleteMethod";

// http://localhost/PersonalWebsite
const domainAddress = process.env.REACT_APP_API_URL;

// Definicje interfejsów dla danych
export interface Course {
  CourseId: number;
  CourseName: string;
  Description: string;
  topics?: Topic[];
}

export interface Topic {
  topicId: number;
  courseId: number;
  name: string;
  details: string;
}

export interface TopicSection {
  topic_id: number;
  section_id: number;
  sequence: number;
}

export interface Section {
  id: number;
  sectionName: string;
  templateType: string;
  templateContext: string[];
}

export interface SectionTemplate {
  section_id: number;
  template_id: number;
  sequence: number;
}

export interface Template {
  id: number;
  type: string;
  context: string;
}

export interface AccountType {
  id: number;
  typeName: string;
}

export interface User {
  id: number;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  classNumber: string;
  accountType_id: number;
  createdAt: Date;
}

interface CoursesContextProps {
  courses: Course[] | null;
  sections: Section[] | null;
  topicSections: TopicSection[] | null;
  sectionTemplates: SectionTemplate[] | null;
  templates: Template[] | null;
  loading: boolean;
  error: string | null;
  UpdateCourse: (
    courseId: number,
    name: string,
    description: string
  ) => Promise<void>;
  UpdateTopic: (
    topicId: number,
    courseId: number,
    name: string,
    details: string
  ) => Promise<void>;
  UpdateSection: (sectionId: number, name: string) => Promise<void>;
  UpdateTemplate: (
    templateId: number,
    type: string,
    context: string
  ) => Promise<void>;
  UpdateTopicSection: (
    topicId: number,
    sectionId: number,
    sequence: number
  ) => Promise<void>;
  UpdateSectionTemplate: (
    section_id: number,
    template_id: number,
    sequence: number
  ) => Promise<void>;
  AddCourse: (name: string, description: string) => Promise<void>;
  AddTopic: (courseId: number, name: string, details: string) => Promise<void>;
  AddSection: (id: number, name: string) => Promise<number>;
  AddTemplate: (type: string, context: string) => Promise<number>;
  AddTopicSection: (
    topicId: number,
    sectionId: number,
    sequence: number
  ) => Promise<void>;
  AddSectionTemplate: (
    sectionId: number,
    templateId: number,
    sequence: number
  ) => Promise<void>;
  DeleteCourse: (courseId: number) => Promise<void>;
  DeleteTopic: (topicId: number) => Promise<void>;
  DeleteTopicSection: (
    topicId: number,
    sectionId: number,
    sequence: number
  ) => Promise<void>;
  DeleteSection: (sectionId: number) => Promise<void>;
  DeleteSectionTemplate: (
    sectionId: number,
    templateId: number
  ) => Promise<void>;
  DeleteTemplate: (templateId: number) => Promise<void>;
}

const CoursesContext = createContext<CoursesContextProps>({
  courses: null,
  sections: null,
  topicSections: null,
  sectionTemplates: null,
  templates: null,
  loading: true,
  error: null,
  UpdateCourse: async () => {},
  UpdateTopic: async () => {},
  UpdateSection: async () => {},
  UpdateTemplate: async () => {},
  UpdateTopicSection: async () => {},
  UpdateSectionTemplate: async () => {},
  AddCourse: async () => {},
  AddTopic: async () => {},
  AddSection: async () => {
    return 1;
  },
  AddTemplate: async () => {
    return 1;
  },
  AddTopicSection: async () => {},
  AddSectionTemplate: async () => {},
  DeleteCourse: async () => {},
  DeleteTopic: async () => {},
  DeleteTopicSection: async () => {},
  DeleteSection: async () => {},
  DeleteSectionTemplate: async () => {},
  DeleteTemplate: async () => {},
});

export const useCourses = () => useContext(CoursesContext);

interface CoursesProviderProps {
  children: ReactNode;
}

export const useSendRequest = () => {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

// function to handle all HTTP requests
const SendRequest = async (
  url: string,
  method: string,
  body: any
): Promise<any> => {
  

  try {
    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      ...(body ? { body: JSON.stringify(body) } : {}),
    });
    
    // Checking the response has JSON content
    const contentType = response.headers.get("Content-Type");

    // If the response contains JSON, try parsing
    const result = contentType && contentType.includes("application/json")
    ? await response.json()
    : await response.text();

    if (!response.ok) {
      throw new Error(result?.message || "An error occurred");
    }

    return result;
  } catch (error) {
    if (error instanceof Error) {
      setError(error.message);
    } else {
      setError("An unknown error occurred");
    }
    setLoading(false);
    throw error;
  }
};

  return {SendRequest};
}


export const CoursesProvider: React.FC<CoursesProviderProps> = ({ children }) => {

  const [courses, setCourses] = useState<Course[] | null>(null);
  const [sections, setSections] = useState<Section[] | null>(null);
  const [topicSections, setTopicSections] = useState<TopicSection[] | null>(null);
  const [sectionTemplates, setSectionTemplates] = useState<SectionTemplate[] | null>(null);
  const [templates, setTemplates] = useState<Template[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // // function to handle all HTTP requests
  // const SendRequest = async (
  //   url: string,
  //   method: string,
  //   body: any
  // ): Promise<any> => {
  //   try {
  //     const response = await fetch(url, {
  //       method,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       ...(body ? { body: JSON.stringify(body) } : {}),
  //     });
      
  //     // Checking the response has JSON content
  //     const contentType = response.headers.get("Content-Type");

  //     // If the response contains JSON, try parsing
  //     const result = contentType && contentType.includes("application/json")
  //     ? await response.json()
  //     : await response.text();

  //     if (!response.ok) {
  //       throw new Error(result?.message || "An error occurred");
  //     }

  //     return result;
  //   } catch (error) {
  //     if (error instanceof Error) {
  //       setError(error.message);
  //     } else {
  //       setError("An unknown error occurred");
  //     }
  //     setLoading(false);
  //     throw error;
  //   }
  // };
// ------------------------------------------------------------------------------------
// ---------------------------- GET ----------------------------
// ------------------------------------------------------------------------------------
useEffect(() => {
  const fetchData = async () => {
    try {
      const coursesData = await getCourses();
      const sectionsData = await getSections();
      const topicSectionsData = await getTopicSections();
      const topicsData = await getTopics();
      const sectionTemplatesData = await getSectionTemplates();
      const templatesData = await getTemplates();

      // Mapowanie tematów na kursy
      const coursesWithTopics = coursesData.data.map((course: Course) => {
        const courseTopics = topicsData.filter((topic: Topic) => topic.courseId === course.CourseId);
        return { ...course, topics: courseTopics };
      });

      setCourses(coursesWithTopics);
      setSections(sectionsData);
      setTopicSections(topicSectionsData);
      setSectionTemplates(sectionTemplatesData);
      setTemplates(templatesData);
      setLoading(false);
    } catch (error) {
      setError(error instanceof Error ? error.message : "An unknown error occurred");
      setLoading(false);
    }
  };

  fetchData();
}, []);

  // ------------------------------------------------------------------------------------
  // ---------------------------- PUT ----------------------------
  // ------------------------------------------------------------------------------------

  const { 
    UpdateCourse, 
    UpdateTopic, 
    UpdateSection, 
    UpdateTemplate, 
    UpdateTopicSection, 
    UpdateSectionTemplate 
  } = usePutMethods();

  // ------------------------------------------------------------------------------------
  // ---------------------------- POST ----------------------------
  // ------------------------------------------------------------------------------------

  const {
    AddCourse,
    AddTopic,
    AddTopicSection,
    AddSection,
    AddSectionTemplate,
    AddTemplate,
  } = usePostMethods();

  // ------------------------------------------------------------------------------------
  // ---------------------------- DELETE ----------------------------
  // ------------------------------------------------------------------------------------

  const {
    DeleteCourse,
    DeleteTopic,
    DeleteTopicSection,
    DeleteSection,
    DeleteSectionTemplate,
    DeleteTemplate,
  } = useDeleteMethods();
  

  return (
    <CoursesContext.Provider
      value={{
        courses,
        sections,
        topicSections,
        sectionTemplates,
        templates,
        loading,
        error,
        UpdateCourse,
        UpdateTopic,
        UpdateSection,
        UpdateTemplate,
        UpdateTopicSection,
        UpdateSectionTemplate,
        AddCourse,
        AddTopic,
        AddSection,
        AddTemplate,
        AddTopicSection,
        AddSectionTemplate,
        DeleteCourse,
        DeleteTopic,
        DeleteTopicSection,
        DeleteSection,
        DeleteSectionTemplate,
        DeleteTemplate,
      }}
    >
      {children}
    </CoursesContext.Provider>
  );
};
