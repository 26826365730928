import React, { useState} from "react";
import style from "./../../didactic.module.css";
import Footer from "../../../Footer";
import Header from "../../HeaderDidactic";
import { useCourses } from "../../../../CoursesContext";

import CourseForm from "./ManageCourse";
import TopicForm from "./ManageTopic";
import SectionForm from "./ManageSection";
import TemplateList from "./ManageTemplate";

const links = [
  { name: "Strona główna", url: "/" },
  { name: "Wszystkie kursy", url: "/course" },
  { name: "Zarządzanie kursami", url: "/course/CourseManagement" },
];

export default function CourseManagement() {
  const {
    courses,
    sections,
    topicSections,
    sectionTemplates,
    templates,
    loading,
    error,
    // UpdateCourse,
    // UpdateTopic,
    // UpdateSection,
    // UpdateTemplate,
    // UpdateTopicSection,
    // UpdateSectionTemplate,
    // DeleteCourse,
    // DeleteTopic,
    // DeleteTopicSection,
    // DeleteSection,
    // DeleteSectionTemplate,
    // DeleteTemplate,
  } = useCourses();
  const [selectedCourse, setSelectedCourse] = useState<number | null>(null);
  const [selectedTopic, setSelectedTopic] = useState<number | null>(null);
  const [selectedSection, setSelectedSection] = useState<number | null>(null);
  // const [selectedSequence, setSelectedSequence] = useState<number | null>(null);
  // const [selectedSequenceTemplate, setSelectedSequenceTemplate] = useState<{[templateId: number]: number | null;}>({});
  const [, setCourseName] = useState<string>("");
  const [, setCourseDescription] = useState<string>("");
  // const [topicName, setTopicName] = useState<string>("");
  // const [topicDetails, setTopicDetails] = useState<string>("");
  // const [sectionName, setSectionName] = useState<string>("");
  const [templateContext, setTemplateContext] = useState<{
    [key: number]: string;
  }>({});
  const [templateType, setTemplateType] = useState<{ [key: number]: string }>({});

  // Support for changes to various form fields
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
  
    switch (name) {
      case "courseSelect":
        setSelectedCourse(Number(value));
        setSelectedTopic(null);
        setSelectedSection(null);
        break;

      case "courseName":
        setCourseName(value);  
        break;

      case "courseDescription":
        setCourseDescription(value); 
        break;

      case "topicSelect":
        setSelectedTopic(Number(value));
        setSelectedSection(null);
        break;
  
      case "sectionSelect":
        setSelectedSection(Number(value));
        break;
  
      // case "sequence":
      //   setSelectedSequence(Number(value));
      //   break;
  
      default:
        console.warn(`Unhandled field: ${name}`);
        break;
    }
  };
  
  const handleTemplateTypeChange = (templateId: number, type: string) => {
    setTemplateType((prevState) => ({ ...prevState, [templateId]: type }));
  };

  const handleTemplateContextChange = (templateId: number, context: string) => {
    setTemplateContext((prevState) => ({
      ...prevState,
      [templateId]: context,
    }));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <Header links={links} />
      <div className="container">
        <div className="row justify-content-center">
          <div
            className={`${style.didacticBlock} col-12 col-md-12 text-center rounded mt-4 mb-4`}
          >
            <h1>Zarządzanie kursami</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="w-100"></div>
          <div className={`col-12 col-md-10 align-items-center mb-4`}>
            <div className={`${style.didacticBlock} rounded p-2`}>
              <form>
                {courses && (
                  <CourseForm
                    selectedCourse={selectedCourse}
                    setSelectedCourse={setSelectedCourse}
                    handleChange={handleChange}
                  />
                )}
                {courses && selectedCourse && (
                  <TopicForm
                    selectedCourse={selectedCourse}
                    selectedTopic={selectedTopic}
                    setSelectedTopic={setSelectedTopic}
                    handleChange={handleChange}
                  />
                )}
                {selectedTopic && sections && topicSections && (
                  <SectionForm
                    selectedTopic={selectedTopic}
                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                    handleChange={handleChange}
                  />
                )}
                {selectedSection && sectionTemplates && templates && (
                  <TemplateList
                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                    templateContext={templateContext}
                    templateType={templateType}
                    handleTemplateTypeChange={handleTemplateTypeChange}
                    handleTemplateContextChange={handleTemplateContextChange}
                  />
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
