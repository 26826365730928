import React from 'react';
import style from './mainPage/home.module.css';

function Footer() {
  return (
    <div className={style.footer}>
      <p>© 2024 Piskorowski Jakub. All rights reserved.</p>
    </div>
  );
};

export default Footer;
