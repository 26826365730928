import React, { useState } from "react";
import {
  Course,
  Section,
  Template,
  TopicSection,
} from "../../../../CoursesContext";
import { useFormHandlers, FormData, handleChange } from "./CreateForm";

interface CreateTemplateFormProps {
  courses: Course[];
  sections: Section[];
  templates: Template[];
  topicSections: TopicSection[];
  onSubmitSectionTemplate: (
    sectionId: number,
    templateId: number,
    sequence: number
  ) => Promise<void>;
  onSubmitTemplate: (type: string, context: string) => Promise<number>;
}

export default function CreateTemplate({
  courses,
  sections,
  templates,
  topicSections,
  onSubmitSectionTemplate,
  onSubmitTemplate,
}: CreateTemplateFormProps) {
  // State for managing active form and selections
  const [activeForm, setActiveForm] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({ templateType: '' });
  const [selectedCourse, setSelectedCourse] = useState<number | null>(null);
  const [selectedTopic, setSelectedTopic] = useState<number | null>(null);
  const [selectedSection, setSelectedSection] = useState<number | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null);
  const [sequence, setSequence] = useState<number | null>(null);

  // Form handlers from a custom hook
  const {
    handleCourseChange,
    handleTopicChange,
    handleSectionChange,
    handleTemplateChange,
  } = useFormHandlers(
    formData,
    setFormData,
    setSelectedCourse,
    setSelectedTopic,
    setSelectedSection,
    setSelectedTemplate
  );

  type FormattedData =
    | string[] // For 'text' and 'image'
    | [string, string] // For 'link'
    | [string, string][]; // For 'codeBlock'

  // Handle form submission based on form name
  const handleFormSubmit = async (formName: string) => {
    if (formName === "AssignTemplate") {
      if (selectedSection && selectedTemplate && sequence !== null) {
        try {
          await onSubmitSectionTemplate(
            selectedSection,
            selectedTemplate,
            sequence
          );

          const structuredData = {
            "Selected Course": selectedCourse || "Not provided",
            "Selected Topic": selectedTopic || "Not provided",
            "Selected Section": selectedSection || "Not provided",
            "Selected Template": selectedTemplate || "Not provided",
            "Selected Sequence": sequence || "Not provided",
          };
          console.log(
            "Submitted Section Data:",
            JSON.stringify(structuredData, null, 3)
          );
          console.log("Template assigned successfully to the section.");
        } catch (error) {
          console.error("Failed to assign template to section:", error);
        }
      }
      // Reset form data and close the form
      // setActiveForm(null);
      // setSelectedCourse(null);
      // setSelectedTopic(null);
      setSelectedSection(null);
      setSelectedTemplate(null);
      // setSequence(null); 
    } else if (formName === "CreateTemplate") {
      if (formData.templateType && selectedSection && sequence) {
        try {
          let formattedData: FormattedData;
          switch (formData.templateType) {
            case "text":
              formattedData = [formData.textContent || ""];
              break;
            case "link":
              formattedData = [formData.link || "", formData.linkName || ""];
              break;
            case "image":
              formattedData = [formData.imageLink || "", formData.imageSourceName || "", formData.imageSourceLink || ""];
              break;
            case "codeBlock":
              formattedData = [
                [formData.language || "", formData.codeContent || ""],
              ];
              break;
            default:
              formattedData = [];
          }
          const formattedDataString = JSON.stringify(formattedData);
          const structuredData = {
            "Selected Course": selectedCourse || "Not provided",
            "Selected Topic": selectedTopic || "Not provided",
            "Selected Section": selectedSection || "Not provided",
            "Selected Template": formData.templateType || "Not provided",
            "Selected Sequence": sequence || "Not provided",
            // "Formated context": formattedDataString || "Not provided",
          };

          console.log(
            "Submitted Section Data:",
            JSON.stringify(structuredData, null, 3)
          );
          console.log("Formatted Data: ", formattedDataString);
          // Get ID submited Template
          const newTemplateId = await onSubmitTemplate(
            formData.templateType,
            formattedDataString
          );
          await console.log("newTemplateId: ", newTemplateId);
          await onSubmitSectionTemplate(
            selectedSection,
            newTemplateId,
            sequence
          );

          // Reset form data and close the form
          // setActiveForm(null);
          // setSelectedCourse(null);
          // setSelectedTopic(null);
          // setSelectedSection(null);
          setSelectedTemplate(null);
          setFormData({
            templateType: "",
            templateName: "",
            textContent: "",
            link: "",
            linkName: "",
            imageLink: "",
            imageSourceName: "",
            imageSourceLink: "",
            language: "",
            codeContent: "",
          });
        } catch (error) {
          console.log("Failed to create template:", error);
        }
      }
    } else {
      console.log(`${formName} Data: `, formData);
    }
  };

  // Return styles based on the selected template type
  const getTextareaStyles = () => {
    switch (formData.templateType) {
      case "text":
        return {
          width: "100%",
          maxWidth: "1000px",
          height: "250px",
        };
      case "link":
        return {
          width: "100%",
          maxWidth: "1000px",
          height: "30px",
        };
      case "image":
        return {
          width: "100%",
          maxWidth: "1000px",
          height: "30px",
        };
      case "codeBlock":
        return {
          width: "100%",
          maxWidth: "1000px",
          height: "300px",
        };
      default:
        return {
          width: "100%",
          maxWidth: "1000px",
          height: "200px",
        };
    }
  };

  // Return placeholder text based on the selected template type
  const getPlaceholder = () => {
    switch (formData.templateType) {
      case "text":
        return "Wprowadź treść";
      //   case "link":
      //     return 'link';
      case "image":
        return "link";
      case "codeBlock":
        return "Kod";
      default:
        return "Enter content";
    }
  };

   // Reusable dropdown for course selection
   const renderCourseSelector = () => (
    <div>
      <label>Kurs:</label>
      <select
        name="selectedCourse"
        value={selectedCourse || ""}
        onChange={handleCourseChange}
      >
        <option value="">Wybierz kurs</option>
        {courses?.map((course) => (
          <option key={course.CourseId} value={course.CourseId}>
            {course.CourseName}
          </option>
        ))}
      </select>
    </div>
  );

  // Reusable dropdown for topic selection
  const renderTopicSelector = () => (
    selectedCourse && (
      <div>
        <label>Temat:</label>
        <select
          name="selectedTopic"
          value={selectedTopic || ""}
          onChange={handleTopicChange}
        >
          <option value="">Wybierz temat</option>
          {courses
            ?.find((course) => course.CourseId === selectedCourse)
            ?.topics?.map((topic) => (
              <option key={topic.topicId} value={topic.topicId}>
                {topic.name}
              </option>
            ))}
        </select>
      </div>
    )
  );

  // Reusable dropdown for section selection
  const renderSectionSelector = () => (
    selectedTopic && (
      <div>
        <label>Sekcja:</label>
        <select
          name="selectedSection"
          value={selectedSection || ""}
          onChange={handleSectionChange}
        >
          <option value="">Wybierz sekcję</option>
          {sections
            ?.filter((section) =>
              topicSections?.some(
                (ts) =>
                  ts.topic_id === selectedTopic && ts.section_id === section.id
              )
            )
            .map((section) => (
              <option key={section.id} value={section.id}>
                {section.sectionName}
              </option>
            ))}
        </select>
      </div>
    )
  );

  // Reusable template selection (radio buttons)
  const renderTemplateSelector = () => (
    selectedSection && (
      <div className="mb-3">
        <label className="form-label">Wybierz szablon:</label>
        <div className="form-check">
          {templates?.map((template) => (
            <div
              key={template.id}
              className="form-check mb-3 p-1 border border-dark"
            >
              <input
                type="radio"
                id={`template-${template.id}`}
                name="selectedTemplate"
                value={template.id}
                className="form-check-input me-2"
                checked={selectedTemplate === template.id}
                onChange={handleTemplateChange}
              />
              <label
                htmlFor={`template-${template.id}`}
                className="form-check-label d-flex align-items-center"
              >
                <span className="me-2">
                  <b>{template.type}</b>
                </span>
                <span>{template.context}</span>
              </label>
            </div>
          ))}
        </div>
      </div>
    )
  );

  // Reusable form for creating templates
  const renderTemplateCreationForm = () => (
    selectedSection && (
      <div>
        <h5>Stwórz nowy szablon</h5>
        <div>
          <label>Typ szablonu:</label>
          <select
            name="templateType"
            value={formData.templateType || ""}
            onChange={(e) =>
              handleChange(e, setFormData, (value) =>
                setFormData((prev) => ({
                  ...prev,
                  templateType: value,
                }))
              )
            }
          >
            <option value="">---</option>
            <option value="text">Text</option>
            <option value="link">Link</option>
            <option value="image">Image</option>
            <option value="codeBlock">CodeBlock</option>
          </select>
        </div>

        {formData.templateType === "text" && (
          <textarea
            name="textContent"
            placeholder={getPlaceholder()}
            className="form-control"
            style={{ ...getTextareaStyles(), resize: "none" }}
            value={formData.textContent || ""}
            onChange={(e) =>
              handleChange(e, setFormData, (value) =>
                setFormData((prev) => ({ ...prev, textContent: value }))
              )
            }
          />
        )}

        {formData.templateType === "link" && (
          <div>
            <input
              type="text"
              name="link"
              placeholder="Link"
              value={formData.link || ""}
              onChange={(e) =>
                handleChange(e, setFormData, (value) =>
                  setFormData((prev) => ({ ...prev, link: value }))
                )
              }
            />
            <input
              type="text"
              name="linkName"
              placeholder="Nazwa"
              value={formData.linkName || ""}
              onChange={(e) =>
                handleChange(e, setFormData, (value) =>
                  setFormData((prev) => ({ ...prev, linkName: value }))
                )
              }
            />
          </div>
        )}

        {formData.templateType === "image" && (
          <>
            <input
              type="text"
              name="imageLink"
              placeholder={getPlaceholder()}
              value={formData.imageLink || ""}
              onChange={(e) =>
                handleChange(e, setFormData, (value) =>
                  setFormData((prev) => ({ ...prev, imageLink: value }))
                )
              }
            />
            <input
                type="text"
                name="imageSourceName"
                placeholder="Nazwa źródła"
                value={formData.imageSourceName || ""}
                onChange={(e) =>
                  handleChange(e, setFormData, (value) =>
                    setFormData((prev) => ({ ...prev, imageSourceName: value }))
                  )
                }
              />
            <input
              type="text"
              name="imageSourceLink"
              placeholder="Link źródła"
              value={formData.imageSourceLink || ""}
              onChange={(e) =>
                handleChange(e, setFormData, (value) =>
                  setFormData((prev) => ({ ...prev, imageSourceLink: value }))
                )
              }
            />

          </>
        )}

        {formData.templateType === "codeBlock" && (
          <div>
            <label>Język:</label>
            <input
              type="text"
              name="language"
              placeholder="Język"
              value={formData.language || ""}
              onChange={(e) =>
                handleChange(e, setFormData, (value) =>
                  setFormData((prev) => ({ ...prev, language: value }))
                )
              }
            />
            <p>
              css, javascript, php, cpp, git, csharp, python, textile (jako html lub zwykły
              tekst)
            </p>
            <textarea
              name="codeContent"
              placeholder={getPlaceholder()}
              className="form-control"
              style={{ ...getTextareaStyles(), resize: "none" }}
              value={formData.codeContent || ""}
              onChange={(e) =>
                handleChange(e, setFormData, (value) =>
                  setFormData((prev) => ({
                    ...prev,
                    codeContent: value,
                  }))
                )
              }
            />
          </div>
        )}
      </div>
    )
  );

  // Reusable input for sequence number
  const renderSequenceInput = () => (
    (selectedSection && (selectedTemplate || formData.templateType)) && (
      <div>
        <label htmlFor="sequence">Sequence:</label>
        <input
          type="number"
          id="sequence"
          value={sequence || ""}
          onChange={(e) => setSequence(Number(e.target.value))}
        />
      </div>
    )
  );

  return (
    <>
      {/* Manage Template Buttons */}
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
          <button onClick={() => setActiveForm("assignTemplate")}>
            Przypisz szablon
          </button>
          <button onClick={() => setActiveForm("createTemplate")}>
            Stwórz nowy szablon
          </button>
        </div>
      </div>

      {/* Assign Template Form */}
      {activeForm === "assignTemplate" && (
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            {renderCourseSelector()}
            {renderTopicSelector()}
            {renderSectionSelector()}
            {renderTemplateSelector()}
            {renderSequenceInput()}
            {sequence && (
            <button onClick={() => handleFormSubmit("AssignTemplate")}>
              Przypisz szablon
            </button>
            )}
          </div>
        </div>
      )}

      {/* Create Template Form */}
      {activeForm === "createTemplate" && (
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            {renderCourseSelector()}
            {renderTopicSelector()}
            {renderSectionSelector()}
            {renderTemplateCreationForm()}
            {renderSequenceInput()}
            {sequence && (
              <button onClick={() => handleFormSubmit("CreateTemplate")}>
              Save Template
              </button>
            )}
            
          </div>
        </div>
      )}

      {/* Sequence input */}
      {/* {renderSequenceInput()} */}
    </>
  );

}
