import React, { useState } from "react";

interface CreateCourseFormProps {
  onSubmitCourse: (courseName: string, courseDescription: string) => Promise<void>;
}

export default function CreateCourseForm({ onSubmitCourse }: CreateCourseFormProps) {
  const [formData, setFormData] = useState({
    courseName: "",
    courseDescription: "",
  });

  // Function to handle input changes and update state
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      // Spread the previous state and update the changed field
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    console.log("Submitting Course Data:", {
      CourseName: formData.courseName,
      CourseDescription: formData.courseDescription,
    });

    try {
      if (formData.courseName && formData.courseDescription) {
        await onSubmitCourse(formData.courseName, formData.courseDescription);
        // Reset form data after successful submission
        setFormData({ courseName: "", courseDescription: "" });
      }
    } catch (error) {
      console.error("Error adding course:", error);
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-6">
        <form onSubmit={handleFormSubmit}>
          <div>
            <label>Nazwa kursu:</label>
            <input
              type="text"
              name="courseName"
              placeholder=""
              value={formData.courseName}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Krótki opis kursu: </label>
            <textarea
              name="courseDescription"
              placeholder=""
              value={formData.courseDescription}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit">Stwórz nowy kurs</button>
        </form>
      </div>
    </div>
  );
}
