import React, { useState, useEffect } from "react";
import {useCourses } from '../../../../CoursesContext';

interface TopicFormProps {
  selectedCourse: number | null;
  selectedTopic: number | null;
  setSelectedTopic: React.Dispatch<React.SetStateAction<number | null>>;
  // topicName: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
}

const TopicForm: React.FC<TopicFormProps> = ({
  selectedCourse,
  selectedTopic,
  setSelectedTopic,
  handleChange,
}) => {
  const {
    courses,
    topicSections,
    UpdateTopic,
    DeleteTopic,
    DeleteTopicSection,
  } = useCourses();

  const topics = courses?.find(course => course.CourseId === selectedCourse)?.topics || [];
  const [topicName, setTopicName] = useState<string>("");
  const [topicDetails, setTopicDetails] = useState<string>("");

  useEffect(() => {
    if (selectedTopic && selectedCourse) {
      const course = courses?.find(
        (course) => course.CourseId === selectedCourse
      );
      const topic = course?.topics?.find(
        (topic) => topic.topicId === selectedTopic
      );
      if (topic) {
        setTopicName(topic.name);
        setTopicDetails(topic.details);
      }
    }
  }, [selectedTopic, selectedCourse, courses]);

  const handleEditTopic = async () => {
    console.log("Edit topic with ID:", selectedTopic);
    console.log("Topic Name:", topicName);
    console.log("Topic Details:", topicDetails);
    if (selectedTopic && selectedCourse) {
      await UpdateTopic(selectedTopic, selectedCourse, topicName, topicDetails);
      setSelectedTopic(null);
      setTopicName("");
      setTopicDetails("");
    }
  };

  const handleDeleteTopic = async (topicId: number) => {
    try {
      // Find all topic sections associated with the topic
      const relatedTopicSections = topicSections?.filter(
        (ts) => ts.topic_id === topicId
      );

      // Delete each topic section associated with the topic
      if (relatedTopicSections) {
        for (const ts of relatedTopicSections) {
          await DeleteTopicSection(ts.topic_id, ts.section_id, ts.sequence);
        }
      }
      await console.log(
        `Successfully deleted each topic section associated with the topic ID: ${topicId}`
      );

      // Call the DeleteTopic function from CourseContext
      await DeleteTopic(topicId);
      setSelectedTopic(null);
      setTopicName("");
      setTopicDetails("");
      await console.log(`Successfully deleted topic ID: ${topicId}`);
    } catch (error) {
      console.error("Failed to delete topic", error);
    }
  };


  return (
    <>
      <div className="mb-3 text-center">
        <label htmlFor="topicSelect" className="form-label">
          <p className='fs-3 fw-bold'>Wybierz temat</p>
        </label>
        <select
          id="topicSelect"
          name="topicSelect"
          className="form-select text-center"
          value={selectedTopic || ''}
          onChange={handleChange}
        >
          <option value="">-- Wybierz temat --</option>
          {topics.map(topic => (
            <option key={topic.topicId} value={topic.topicId}>
              {topic.name}
            </option>
          ))}
        </select>
      </div>
      {selectedTopic && (
        <>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="topicName" className="form-label me-2">Nazwa tematu: </label>
            <input
              id="topicName"
              type="text"
              className="form-control w-auto"
              value={topicName}
              onChange={(e) => setTopicName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="topicDetails" className="form-label me-2">Opis tematu</label>
            <textarea
              id="topicDetails"
              className="form-control"
              value={topicDetails}
              onChange={(e) => setTopicDetails(e.target.value)}
            />
          </div>
          <div className='mb-3 text-center'>
            <button type="button" className="btn btn-primary" onClick={handleEditTopic}>Edytuj temat</button>
            <button type="button" className="btn btn-danger" onClick={() => selectedTopic && handleDeleteTopic(selectedTopic)}>Usuń temat</button>
          </div>
        </>
      )}
    </>
  );
};

export default TopicForm;
