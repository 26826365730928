import React, { useState } from "react";
import { Course } from "../../../../CoursesContext";

interface CreateTopicFormProps {
  courses: Course[];
  onSubmitTopic: (
    courseId: number,
    name: string,
    details: string
  ) => Promise<void>;
}

export default function CreateTopic({
  courses,
  onSubmitTopic,
}: CreateTopicFormProps) {
  // State to store selected course and form data
  const [selectedCourse, setSelectedCourse] = useState<number | null>(null);
  const [formData, setFormData] = useState({
    topicName: "",
    topicDescription: "",
  });

  // Universal handler for form input changes
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    if (name === "selectedCourse") {
      // Update the selected course state
      setSelectedCourse(Number(value));
    } else {
      // Update the form data state
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  // Function to handle form submission
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (selectedCourse === null) {
      console.error("No course selected");
      return;
    }

    try {
      if (selectedCourse && formData.topicName && formData.topicDescription) {
        console.log("Submitting Topic Data:", {
          SelectedCourse: selectedCourse,
          TopicName: formData.topicName,
          TopicDescription: formData.topicDescription,
        });
        await onSubmitTopic(
          selectedCourse,
          formData.topicName,
          formData.topicDescription
        );

        // Reset the form data and selected course after submission
        setFormData({ topicName: "", topicDescription: "" });
        setSelectedCourse(null);
      }
    } catch (error) {
      console.error("Error adding topic:", error);
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-6">
        <form onSubmit={handleFormSubmit}>
          <div>
            <label>Kurs: </label>
            <select
              name="selectedCourse"
              value={selectedCourse || ""}
              onChange={handleChange}
              required
            >
              <option value="">Wybierz kurs</option>
              {courses?.map((course) => (
                <option key={course.CourseId} value={course.CourseId}>
                  {course.CourseName}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Temat: </label>
            <input
              type="text"
              name="topicName"
              placeholder="Nazwa tematu"
              value={formData.topicName}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Opis:</label>
            <textarea
              name="topicDescription"
              placeholder="Opis tematu"
              value={formData.topicDescription}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit">Stwórz nowy temat</button>
        </form>
      </div>
    </div>
  );
}
