import "./App.css";

import React, {useEffect, useState} from "react";
import { Routes, Route, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";

import Home from "./components/mainPage/Home";
import ProfInformation from "./components/mainPage/ProfInformation";
import Hobby from "./components/mainPage/Hobby";
import Projects from "./components/mainPage/Projects";
import DidacticEnter from "./components/didactic/DidacticEnter";
import Courses from "./components/didactic/Didactic";
import TopicList from "./components/didactic/TopicList";
import TopicDetail from "./components/didactic/TopicDetail";

import CourseManagement from "./components/didactic/admin-panel/courseManagement/ManageForm";
import CreateCourse from "./components/didactic/admin-panel/createCourse/CreateForm";

const TrackRouteChange = ({ isConsentGranted }: { isConsentGranted: boolean }) => {
  const location = useLocation();
  // console.log("Location path: ", location.pathname);

  useEffect(() => {
    if (isConsentGranted) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'pageview',
          page: location.pathname, 
          consentGranted: 'true'
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [location, isConsentGranted]);
  return null;
};

// const stringToBool = (str: string): boolean => {
//   return str.toLowerCase() === "true";
// };

localStorage.setItem("flag", "true");
function App() {

  const [isConsentGranted, setIsConsentGranted] = useState(() => {
    return localStorage.getItem("consentGranted") === "true";
  });

  useEffect(() => {
    const trackID = process.env.REACT_APP_GA_TRACKING_ID;
    const consent = getCookieConsentValue() === "true";

    if (consent) {
      setIsConsentGranted(true);
    }

    console.log("isConsentGranted", isConsentGranted);
    if (trackID) {
      const tagManagerArgs = {
        gtmId: trackID,
        dataLayer: {
          event: 'consent',
          consentGranted: 'true',
          ad_storage: 'granted',
          analytics_storage: 'granted',
        }
      };
      TagManager.initialize(tagManagerArgs);
    } 
  }, [isConsentGranted]);

  const handleConsent = ({ granted }: { granted: boolean }) => {
    setIsConsentGranted(granted);
    localStorage.setItem("consentGranted", granted.toString());
  };

  
  

  return (
    <div className="App">
       {/* Add a consent banner */}
      <CookieConsent
        location="top"
        buttonText="Akceptuję"
        // enableDeclineButton
        declineButtonText="Odrzucam"
        onAccept={() => handleConsent({granted:true})}
        onDecline={() => handleConsent({granted:false})}
        cookieName="userConsent"
        overlay
        style={{ background: "white" }}
        buttonStyle={{ background:"#3cb371", color: "black", fontSize:"13px"}}
        declineButtonStyle={{background:"#cfcfcf", color: "black", fontSize: "13px"}}
        overlayStyle={{ 
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 1000, // Nakładka wyżej od innych elementów
        }}
        // expires={150}
      >
        <b>Używamy plików cookie w celu analizy ruchu na stronie.</b><br/>

        Klikając "Akceptuję", wyrażasz zgodę na przechowywanie danych dotyczących analityki, co pozwala nam ulepszać nasze usługi i dostarczać Ci lepiej dopasowane treści. Jeżeli nie wyrażasz zgody, możesz w każdej chwili opuścić stronę. Mamy nadzieję, że wrócisz do nas w przyszłości.
      </CookieConsent>

      <TrackRouteChange isConsentGranted={isConsentGranted} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profinformation" element={<ProfInformation />} />
        <Route path="/hobby" element={<Hobby />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/didacticenter" element={<DidacticEnter />} />
        <Route path="/course" element={<Courses />} />
        <Route path="/course/:courseName" element={<TopicList/>} />
        <Route path="/course/:courseName/topic/:topicName" element={<TopicDetail/>} />

        <Route path="/course/CourseManagement" element={<CourseManagement/>} />
        <Route path="/course/CreateCourse" element={<CreateCourse/>} />
      </Routes>
    </div>
  );
}

export default App;
