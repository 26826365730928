import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { CoursesProvider } from './CoursesContext';

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

const root = (ReactDOM as any).createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <CoursesProvider>
      <App />
    </CoursesProvider>
  </BrowserRouter>
);