import React, { useState, useEffect } from "react";
import { useCourses } from "../../../../CoursesContext";

interface SectionFormProps {
  selectedTopic: number | null;
  selectedSection: number | null;
  setSelectedSection: React.Dispatch<React.SetStateAction<number | null>>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
}

const SectionForm: React.FC<SectionFormProps> = ({
  selectedTopic,
  selectedSection,
  setSelectedSection,
  handleChange,
}) => {
  const {
    sections,
    topicSections,
    sectionTemplates,
    UpdateSection,
    UpdateTopicSection,
    DeleteTopicSection,
    DeleteSection,
    DeleteSectionTemplate,
  } = useCourses();

  const sectionOptions = sections?.filter((section) =>
    topicSections?.some(
      (ts) => ts.section_id === section.id && ts.topic_id === selectedTopic
    )
  );
  const [sectionName, setSectionName] = useState<string>("");
  const [selectedSequence, setSelectedSequence] = useState<number | null>(null);

  useEffect(() => {
    if (selectedSection) {
      const section = sections?.find(
        (section) => section.id === selectedSection
      );
      if (section) {
        setSectionName(section.sectionName);

        // Get the current sequence value for the selected section and topic
        const topicSection = topicSections?.find(
          (ts) =>
            ts.section_id === selectedSection && ts.topic_id === selectedTopic
        );
        if (topicSection) {
          setSelectedSequence(topicSection.sequence);
        } else {
          setSelectedSequence(null);
        }
      }
    }
  }, [selectedSection, sections, topicSections, selectedTopic]);

  const handleEditSection = async () => {
    console.log("Selected topic: ", selectedTopic);
    console.log("Selected section:", selectedSection);
    console.log("Section Name:", sectionName);
    console.log("Selected Sequence:", selectedSequence);
    if (selectedSection) {
      // Update the section name
      await UpdateSection(selectedSection, sectionName);

      if (selectedSequence && selectedTopic) {
        // Find all sections related to the selected topic and section
        const relatedTopicSections = topicSections?.filter(
          (ts) =>
            ts.topic_id === selectedTopic && ts.section_id === selectedSection
        );

        // Update each of the related sections
        if (relatedTopicSections) {
          for (const ts of relatedTopicSections) {
            console.log("Handler sequence", selectedSequence);
            await UpdateTopicSection(
              ts.topic_id,
              ts.section_id,
              selectedSequence
            );
          }
        }
      }
      // Reset form
      setSelectedSection(null);
      setSectionName("");
    }
  };
  const handleDeleteSectionAssignmentTopic = async (
    sectionId: number,
    topicId: number,
    sequence: number
  ) => {
    try {
      // Directly delete the specific topic-section association
      await DeleteTopicSection(topicId, sectionId, sequence);

      await console.log(
        `Successfully deleted topic-section association for Topic ID: ${topicId} and Section ID: ${sectionId}`
      );
    } catch (error) {
      console.error("Failed to delete topic-section association", error);
    }
  };

  const handleDeleteSection = async (
    sectionId: number,
    topicId: number,
    sequence: number
  ) => {
    try {
      // Directly delete the specific topic-section association
      await DeleteTopicSection(topicId, sectionId, sequence);

      await console.log(
        `Successfully deleted topic-section association for Topic ID: ${topicId} and Section ID: ${sectionId}`
      );

      // Find all section templates associated with the section
      const relatedSectionTemplates = sectionTemplates?.filter(
        (st) => st.section_id === sectionId
      );

      // Delete each section template associated with the section
      if (relatedSectionTemplates) {
        for (const st of relatedSectionTemplates) {
          await DeleteSectionTemplate(st.section_id, st.template_id);
        }
      }

      // Delete Section
      await DeleteSection(sectionId);
      // Reset form
      setSelectedSection(null);
      setSectionName("");
      await console.log(`Successfully deleted section ID: ${sectionId}`);
    } catch (error) {
      console.error("Failed to delete section", error);
    }
  };

  return (
    <>
      {sectionOptions && (
        <div className="mb-3 text-center">
          <label htmlFor="sectionSelect" className="form-label">
            <p className="fs-3 fw-bold">Wybierz sekcję</p>
          </label>
          <select
            id="sectionSelect"
            name="sectionSelect"
            className="form-select text-center"
            value={selectedSection || ""}
            onChange={handleChange}
          >
            <option value="">-- Wybierz sekcję --</option>
            {sectionOptions.map((section) => (
              <option key={section.id} value={section.id}>
                {section.sectionName}
              </option>
            ))}
          </select>
        </div>
      )}
      {selectedSection && selectedTopic && (
        <>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="sectionName" className="form-label me-2">
              Nazwa sekcji:{" "}
            </label>
            <input
              id="sectionName"
              type="text"
              className="form-control w-auto"
              value={sectionName}
              onChange={(e) => setSectionName(e.target.value)}
            />
          </div>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="sequenceNumber" className="form-label me-2">
              Kolejność:{" "}
            </label>
            <input
              id="sequenceNumber"
              type="number"
              className="form-control w-auto"
              value={selectedSequence || ""}
              onChange={(e) =>
                setSelectedSequence(
                  e.target.value !== "" ? parseInt(e.target.value) : null
                )
              }
            />
          </div>
          <div className="mb-3 text-center">
            <button
              type="button"
              className="btn btn-primary m-2"
              onClick={handleEditSection}
            >
              Edytuj sekcję
            </button>
            <button
              type="button"
              className="btn btn-danger m-2"
              onClick={() =>
                selectedSection &&
                selectedSequence &&
                handleDeleteSectionAssignmentTopic(
                  selectedSection,
                  selectedTopic,
                  selectedSequence
                )
              }
            >
              Usuń przypisanie do tematu
            </button>
            <button
              type="button"
              className="btn btn-danger m-2"
              onClick={() =>
                selectedSection &&
                selectedSequence &&
                handleDeleteSection(
                  selectedSection,
                  selectedTopic,
                  selectedSequence
                )
              }
            >
              Usuń sekcję
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default SectionForm;
