import { useState } from "react";
import {Course, Section, Topic, TopicSection, SectionTemplate, Template, useSendRequest} from '../CoursesContext';

const domainAddress = process.env.REACT_APP_API_URL;

export const useDeleteMethods = () => {
  const [courses, setCourses] = useState<Course[] | null>(null);
  const [sections, setSections] = useState<Section[] | null>(null);
  const [topicSections, setTopicSections] = useState<TopicSection[] | null>(null);
  const [sectionTemplates, setSectionTemplates] = useState<SectionTemplate[] | null>(null);
  const [templates, setTemplates] = useState<Template[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  const {SendRequest} = useSendRequest();

  const DeleteCourse = async (courseId: number) => {
    try {
      // 1. Send a DELETE request to remove the course
      await SendRequest(`${domainAddress}/backend/api/course`, "DELETE", {
        id: courseId,
      });
  
      // If the deletion is successful, update the local state to remove the course
      setCourses((prevCourses) =>
        prevCourses?.filter((course) => course.CourseId !== courseId) || null
      );
    } catch (error) {
      setError(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  };

  const DeleteTopic = async (topicId: number) => {
    try {
      // 1. Send a DELETE request to remove the topic
      await SendRequest(`${domainAddress}/backend/api/topic`, "DELETE", {
        id: topicId,
      });
  
      // If the deletion is successful, update the local state to remove the topic from its course
      setCourses((prevCourses) =>
        prevCourses?.map((course) => ({
          ...course,
          topics: course.topics?.filter((topic) => topic.topicId !== topicId),
        })) || null
      );
    } catch (error) {
      setError(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  };

  const DeleteTopicSection = async (
    topicId: number,
    sectionId: number,
    sequence: number
  ) => {
    try {
      // 1. Send a DELETE request to remove the topic-section relation
      await SendRequest(`${domainAddress}/backend/api/topicSection`, "DELETE", {
        topic_id: topicId,
        section_id: sectionId,
        sequence,
      });
  
      // If successful, update the local state to remove the association
      setTopicSections((prevTopicSections) =>
        prevTopicSections?.filter(
          (ts) =>
            !(
              ts.topic_id === topicId &&
              ts.section_id === sectionId &&
              ts.sequence === sequence
            )
        ) || null
      );
    } catch (error) {
      setError(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  };

  const DeleteSection = async (sectionId: number) => {
    try {
      // 1. Send a DELETE request to remove the section
      await SendRequest(`${domainAddress}/backend/api/section`, "DELETE", {
        id: sectionId,
      });
  
      // If successful, update the local state to remove the section
      setSections((prevSections) =>
        prevSections?.filter((section) => section.id !== sectionId) || null
      );
    } catch (error) {
      setError(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  };

  const DeleteSectionTemplate = async (
    sectionId: number,
    templateId: number,
  ) => {
    try {
      // 1. Send a DELETE request to remove the section-template relation
      await SendRequest(`${domainAddress}/backend/api/sectionTemplate`, "DELETE", {
        section_id: sectionId,
        template_id: templateId,
      });
      // If successful, update the local state to remove the association
      setSectionTemplates((prevSectionTemplates) =>
        prevSectionTemplates?.filter(
          (st) =>
            !(st.section_id === sectionId && st.template_id === templateId)
        ) || null
      );
    } catch (error) {
      setError(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  };

  const DeleteTemplate = async (templateId: number) => {
    try {
      // 1. Send a DELETE request to remove the template
      await SendRequest(`${domainAddress}/backend/api/template`, "DELETE", {
        id: templateId,
      });
  
      // If successful, update the local state to remove the template
      setTemplates((prevTemplates) =>
        prevTemplates?.filter((template) => template.id !== templateId) || null
      );
    } catch (error) {
      setError(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  };

  return {
    DeleteCourse,
    DeleteTopic,
    DeleteTopicSection,
    DeleteSection,
    DeleteSectionTemplate,
    DeleteTemplate,
  };

}