import React from "react";
import { Link, useParams } from "react-router-dom";
import style from "./didactic.module.css";
import Footer from "../Footer";
import Header from "./HeaderDidactic";
import { useCourses, Course, Topic } from "../../CoursesContext";

function TopicList() {
  const { courseName } = useParams<{ courseName: string }>();
  const { courses, loading, error } = useCourses();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!courseName) return <div>No course ID provided</div>;
  const decodedCourseName = decodeURI(courseName);

  // const numericCourseId = Number(courseId);
  // if (isNaN(numericCourseId)) return <div>Invalid course ID</div>;

  const course = courses?.find((c) => c.CourseName === decodedCourseName);
  if (!course) return <div>No course found</div>;

  // Tematy związane z kursami
  const topics = course.topics || [];

  const links = [
    { name: "Strona główna", url: "/" },
    { name: "Wszystkie kursy", url: "/course" },
    { name: course.CourseName, url: `/course/${encodeURI(course.CourseName)}` },
  ];

  return (
    <>
      <Header links={links} />
      <div className="container">
        <div className="row justify-content-center">
          <div className={`${style.didacticBlock} col-12 col-md-6 text-center rounded mt-4 mb-4`}>
            <h1>{course.CourseName}</h1>
            <p>{course.Description}</p>
          </div>
          <div className="w-100"></div>
          <div className={`${style.didacticBlock} col-12 col-md-6 align-items-center rounded mb-4`}>
            <b>Tematy:</b>
            <ol>
              {topics.length > 0 ? (
                topics.map((topic) => (
                  <li key={topic.topicId}>
                    <Link to={`/course/${encodeURI(course.CourseName)}/topic/${encodeURI(topic.name)}`}>
                      {topic.name}
                    </Link>
                  </li>
                ))
              ) : (
                <li>No topics found</li>
              )}
            </ol>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TopicList;
