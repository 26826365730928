import React, { useState } from "react";
import { Course, Section, Topic } from "../../../../CoursesContext";

interface CreateSectionFormProps {
  courses: Course[];
  sections: Section[];
  onSubmitSection: (topicId: number, sectionName: string) => Promise<number>;
  onSubmitTopicSection: (
    topicId: number,
    sectionId: number,
    sequence: number
  ) => Promise<void>;
}

type SelectionKeys = "selectedCourse" | "selectedTopic" | "selectedSection";

export default function CreateSection({
  courses,
  sections,
  onSubmitSection,
  onSubmitTopicSection,
}: CreateSectionFormProps) {
  // State to manage which form is active and selected options
  const [activeForm, setActiveForm] = useState<string | null>(null);
  const [selection, setSelection] = useState({
    selectedCourse: null as number | null,
    selectedTopic: null as number | null,
    selectedSection: null as number | null,
    selectedSequence: null as number | null,
  });
  const [formData, setFormData] = useState({ sectionName: "" });

  // Handle changes in input fields and selects
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle changes in selection fields with optional reset of other fields
  const handleSelectionChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    resetFields: SelectionKeys[] = []
  ) => {
    const { name, value } = e.target;
    const nameKey = name as SelectionKeys;

    setSelection((prevSelection) => {
      const updatedSelection = { ...prevSelection, [nameKey]: Number(value) };

      // Reset specified fields
      resetFields.forEach((field) => {
        updatedSelection[field] = null;
      });

      return updatedSelection;
    });
  };

  // Handle form submission based on the active form type
  const handleFormSubmit = async (formName: string) => {
    if (formName === "AssignSection") {
      const structuredData = {
        "Selected Course": selection.selectedCourse || "Not provided",
        "Selected Topic": selection.selectedTopic || "Not provided",
        "Selected Section": selection.selectedSection || "Not provided",
        "Sequence number": selection.selectedSequence || "Not provided",
      };
      console.log(
        "Assign Section Data:",
        JSON.stringify(structuredData, null, 3)
      );

      if (
        selection.selectedTopic &&
        selection.selectedSection &&
        selection.selectedSequence
      ) {
        try {
          // Update topic-section relationship
          await onSubmitTopicSection(
            selection.selectedTopic,
            selection.selectedSection,
            selection.selectedSequence
          );
          console.log("Section assigned successfully.");
        } catch (error) {
          console.error("Error assigning section:", error);
        }
      }
    } else if (formName === "CreateSection") {
      const structuredData = {
        "Selected Course": selection.selectedCourse || "Not provided",
        "Selected Topic": selection.selectedTopic || "Not provided",
        "Section name": formData.sectionName || "Not provided",
        "Sequence number": selection.selectedSequence || "Not provided",
      };
      console.log(
        "Create Section Data:",
        JSON.stringify(structuredData, null, 3)
      );

      if (
        selection.selectedTopic &&
        formData.sectionName &&
        selection.selectedSequence
      ) {
        try {
          const newSectionId = await onSubmitSection(
            selection.selectedTopic,
            formData.sectionName
          );
          await console.log("newSectionId: ", newSectionId);
          await onSubmitTopicSection(
            selection.selectedTopic,
            newSectionId,
            selection.selectedSequence
          );
          console.log("Section created and assigned successfully.");
        } catch (error) {
          console.error("Error creating or assigning section:", error);
        }
      }
    }
    // Reset form data and close the form
    setFormData({ sectionName: "" });
    setActiveForm(null);
  };

  // Render the course selection dropdown
  const renderCourseSelect = () => (
    <>
      <label>Kurs: </label>
      <select
        name="selectedCourse"
        value={selection.selectedCourse || ""}
        onChange={(e) =>
          handleSelectionChange(e, ["selectedTopic", "selectedSection"])
        }
      >
        <option value="">Wybierz kurs</option>
        {courses?.map((course: Course) => (
          <option key={course.CourseId} value={course.CourseId}>
            {course.CourseName}
          </option>
        ))}
      </select>
    </>
  );

  // Render the topic selection dropdown based on the selected course
  const renderTopicSelect = () =>
    selection.selectedCourse && (
      <>
        <label>Temat: </label>
        <select
          name="selectedTopic"
          value={selection.selectedTopic || ""}
          onChange={(e) => handleSelectionChange(e, ["selectedSection"])}
        >
          <option value="">Wybierz temat</option>
          {courses
            ?.find((course) => course.CourseId === selection.selectedCourse)
            ?.topics?.map((topic: Topic) => (
              <option key={topic.topicId} value={topic.topicId}>
                {topic.name}
              </option>
            ))}
        </select>
      </>
    );

  // Render the section selection dropdown based on the selected topic
  const renderSectionSelect = () =>
    selection.selectedTopic && (
      <>
        <label>Sekcja: </label>
        <select
          name="selectedSection"
          value={selection.selectedSection || ""}
          onChange={(e) => handleSelectionChange(e)}
        >
          <option value="">Wybierz sekcję</option>
          {sections?.map((section: Section) => (
            <option key={section.id} value={section.id}>
              {section.sectionName}
            </option>
          ))}
        </select>
      </>
    );

  // Render the sequence input field for assigning section
  const renderSequenceInput = () => (
    <>
      <label>Kolejność: </label>
      <input
        type="number"
        name="selectedSequence"
        placeholder="Numer kolejności"
        value={selection.selectedSequence || ""}
        onChange={(e) =>
          setSelection((prevSelection) => ({
            ...prevSelection,
            selectedSequence: Number(e.target.value),
          }))
        }
        required
      />
    </>
  );

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 text-center">
          {/* Buttons to toggle between assigning and creating sections */}
          <button onClick={() => setActiveForm("AssignSection")}>
            Przypisz sekcję
          </button>
          <button onClick={() => setActiveForm("CreateSection")}>
            Stwórz nową sekcję
          </button>
        </div>
      </div>

      {activeForm && (
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <div>{renderCourseSelect()}</div>
            {renderTopicSelect()}
            <div>{activeForm === "AssignSection" && renderSectionSelect()}</div>
            <div>
              {selection.selectedTopic && activeForm === "CreateSection" && (
                <>
                  <label>Sekcja: </label>
                  <input
                    type="text"
                    name="sectionName"
                    placeholder="Nazwa sekcji"
                    value={formData.sectionName}
                    onChange={handleInputChange}
                    required
                  />
                </>
              )}
            </div>
            <div>{selection.selectedTopic && renderSequenceInput()}</div>
            {selection.selectedTopic && (
              <button onClick={() => handleFormSubmit(activeForm)}>
                {activeForm === "AssignSection"
                  ? "Przypisz sekcję"
                  : "Stwórz nową sekcję"}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
