import React from "react";
import rowerStelvio from "../../assets/bikeStelvio.jpg";
import jakubLaptop from "../../assets/jakubLaptop.jpg";
import jakubMedals from "../../assets/jakubMedals.jpg";
import jakubGun from "../../assets/jakubGun.jpg";
import jakubBook1 from "../../assets/jakubBook1.jpg";

// import './home.css';
import style from "./home.module.css";

import Header from "../Header";
import Footer from "../Footer";

interface SectionProps{
  link: string;
  classIco: string;
  pathIco: string;
  pathIco2: string | null;
  image: string;
  title: string;
}

function Home() {
  return (
    // <div className="Home">
    <div className={style.Home}>
      <Header
        image1={rowerStelvio}
        heightImage={600}
        title="Piskorowski Jakub"
        description="Jestem pasjonatem kolarstwa szosowego oraz ergometrów wioślarskich. Jako nauczyciel z zamiłowaniem dzielę się wiedzą z uczniami, a moje zainteresowanie programowaniem pozwala mi na ciągły rozwój w tej dziedzinie."
        media={true}
        buttonMain={false}
      />

      <main>
        <section className={style.additionalSection}>
          <h1>Sekcje tematyczne</h1>
          <h5>Wybierz dziedzinę, która cię interesuje</h5>
          <div className={style.blocks}>
            <Section
              link=""
              classIco="bi bi-person-vcard-fill"
              pathIco="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5M9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8m1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5m-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96q.04-.245.04-.5M7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0"
              pathIco2={null}
              image={jakubLaptop}
              title="Informacje zawodowe"
            />
            <Section
              link=""
              classIco="bi bi-bicycle"
              pathIco="M4 4.5a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1v.5h4.14l.386-1.158A.5.5 0 0 1 11 4h1a.5.5 0 0 1 0 1h-.64l-.311.935.807 1.29a3 3 0 1 1-.848.53l-.508-.812-2.076 3.322A.5.5 0 0 1 8 10.5H5.959a3 3 0 1 1-1.815-3.274L5 5.856V5h-.5a.5.5 0 0 1-.5-.5m1.5 2.443-.508.814c.5.444.85 1.054.967 1.743h1.139zM8 9.057 9.598 6.5H6.402zM4.937 9.5a2 2 0 0 0-.487-.877l-.548.877zM3.603 8.092A2 2 0 1 0 4.937 10.5H3a.5.5 0 0 1-.424-.765zm7.947.53a2 2 0 1 0 .848-.53l1.026 1.643a.5.5 0 1 1-.848.53z"
              pathIco2={null}
              image={jakubMedals}
              title="Hobby"
            />
            <Section
              link=""
              classIco="bi bi-code-slash"
              pathIco="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0m6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0"
              pathIco2={null}
              image={jakubGun}
              title="Projekty"
            />
            <Section
              link="/didacticenter"
              classIco="bi bi-mortarboard-fill"
              pathIco="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917z"
              pathIco2="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466z"
              image={jakubBook1}
              title="Materiały dydaktyczne"
            />
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Home;

function Section({ link, classIco, pathIco, pathIco2, image, title }: SectionProps) {
  return (
    <div className={style.block}>
      <a href={link} className={style.block}>
        <div className={style.icon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="currentColor"
            className={classIco}
            viewBox="0 0 16 16"
          >
            <path d={pathIco} />
            {pathIco2 && <path d={pathIco2} />}
          </svg>
        </div>
        <img src={image} alt="Przykładowe zdjęcie" />
        <h4>{title}</h4>
      </a>
    </div>
  );
}
