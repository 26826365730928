import React from "react";

function ProfInformation() {
    return (
        <div className="ProfInformation">
            <h1>profInformation</h1>
            <a href="/">Back</a>
        </div>
    );

};

export default ProfInformation;

