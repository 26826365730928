const domainAddress = process.env.REACT_APP_API_URL;

// function for handling GET queries
const fetchData = async (endpoint: string) => {
  try {
    const response = await fetch(`${domainAddress}${endpoint}`);
    if (!response.ok) {
      throw new Error("Error fetching data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error instanceof Error ? error.message : "Unknown error");
  }
};

export const getCourses = async () => {
  return await fetchData("/backend/api/course");
};

export const getSections = async () => {
  return await fetchData("/backend/api/section");
};

export const getTopicSections = async () => {
  return await fetchData("/backend/api/topicSection");
};

export const getTopics = async () => {
  return await fetchData("/backend/api/topic");
};

export const getSectionTemplates = async () => {
  return await fetchData("/backend/api/sectionTemplate.php");
};

export const getTemplates = async () => {
  return await fetchData("/backend/api/template.php");
};
