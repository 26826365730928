import React from 'react';
import style from './headerFooterDidactic.module.css';

interface Link {
  name: string;
  url: string;
}

interface HeaderDidacticProps {
  links: Link[];
}

function HeaderDidactic({links}: HeaderDidacticProps) {
  console.log(links);
  return (
    <header className={`${style.header} container-fluid py-3`}>
      <div className="row">
        <div className="col-12 col-md-12 w-75 position-relative top-0 start-50 translate-middle-x">
          <div className={`${style.title1}  text-center p-3`}>
            <a href="/" className="text-decoration-none display-4 fw-bold">
              PiskorowskiJakub.pl
            </a>
          </div>
          <div className={`${style.menu}  text-center rounded-5`}>
            <Menu links={links} />
          </div>
        </div>
        {/* <div className="col-12 col-md-3 text-center">
          <div className={`${style.profile} col align-items-center rounded-2`}>
            <p>Witaj! Miłego dnia :) </p>
          </div>
          <div className="col align-items-end">
            <button disabled className="btn btn-primary btn-sm">Twój profil</button>
            <button disabled className="btn btn-secondary btn-sm">Wyloguj się</button>
          </div>
        </div> */}
      </div>
    </header>
  );
};

interface MenuProps {
  links: Link[];
}

function Menu({links}: MenuProps) {
  return (
    <>
      {links.map((link, index) => (
        <a
          key={index}
          href={link.url}
          className={`link-item ${index === links.length - 1 ? 'text-info text-decoration-none' : 'text-decoration-none'}`}
        >
          {' / '}{link.name}
        </a>
      ))}
      <span className="text-info text-decoration-none"> ...</span>
    </>
  );
};

export default HeaderDidactic;
